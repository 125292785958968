import { Formik }				from 'formik';
import *				as Yup	from "yup";

import Row						from 'react-bootstrap/Row';
import Col						from 'react-bootstrap/Col';
import Form						from 'react-bootstrap/Form';
import FloatingLabel			from 'react-bootstrap/FloatingLabel';
import Button					from 'react-bootstrap/Button';

import { useState, useEffect }	from 'react'





export default function FormSpaceCreate() {
	const validate = Yup.object({
		idEspacio: Yup.number().required('Required'),
		nombreEspacio: Yup.string().required('Required'),
		capacidad: Yup.number().required('Required'),
		piso: Yup.number().required('Required'),

	}).defined();

	const [positions,	setPositions]	= useState([])
	const [floors,		setFloors]		= useState([])
	const [create,		setCreate]		= useState([])

	useEffect(() => {
		fetch(
			process.env.REACT_APP_API_URL + "/consult_floor_space/", {
																		'method': 'GET',
																		headers: {'Content-Type': 'applications/json'},
																	}
		)
		.then(  resp => resp.json())
		.then(  resp => {setPositions(resp)})
		.catch( error => console.error(error))
	}, [])

	useEffect(() => {
		fetch(
			process.env.REACT_APP_API_URL + "/consult_floor/", {
																	'method': 'GET',
																	headers: {'Content-Type': 'applications/json'},
																}
		)
		.then(  resp => resp.json())
		.then(  resp => {setFloors(resp)})
		.catch( error => console.error(error))
	}, [])

	console.log(floors)
	return (
		<div>
			<h2>Crear un nuevo puesto de trabajo</h2>
			<Formik
				initialValues	= {{
					idEspacio: Math.floor(((new Date()).getTime() - (new Date(1970, 1, 1, 0, 0, 0, 0)).getTime()) / 1000),
					nombreEspacio: undefined,
					capacidad: undefined,
					piso: undefined,
					creadoPor: localStorage.getItem('email')
				}}

				validationSchema	= {validate}

				onSubmit	= {(values) => {
					console.log(JSON.stringify(values))
					fetch(
						process.env.REACT_APP_API_URL + "/create_floor_space/", {
																					'method': 'POST',
																					headers: {'Content-Type': 'applications/json'},
																					body: JSON.stringify(values)
																				}
					)
						.then(resp => resp.json())
						.then(resp => {setCreate(resp)})
						.catch(error => console.error(error))
						.finally(refreshpage)
				}}
			>
				{
					({
						handleSubmit,
						handleChange,
						handleBlur,
						values,
						errors,
						isSubmitting
					}) => (
						<Form noValidate onSubmit	= {handleSubmit}>
							<Row>
								<Form.Group as	= {Col} md	= "4" controlId	= "validationFormik01">
									<Form.Label><span>*</span>Puesto de trabajo</Form.Label>
									<FloatingLabel controlId = "nombreEspacio" label = "Puesto">
									<Form.Control
											type		= "text"
											name		= "nombreEspacio"
											onChange	= {handleChange}
											onBlur		= {handleBlur}
											value		= {values.nombreEspacio}
											required
										/>
									</FloatingLabel>
									{errors.nombreEspacio && (<Form.Label className	= 'Alert'>Debe de seleccionar un puesto de trabajo</Form.Label>)}
								</Form.Group>
								
								<Form.Group as	= {Col} md	= "4" controlId	= "validationFormik01">
									<Form.Label><span>*</span>Ingrese la cantidad</Form.Label>
									<FloatingLabel controlId = "capacidad" label = "Ingrese el tipo de espacio">
										<Form.Control
											type		= "number"
											name		= "capacidad"
											onChange	= {handleChange}
											onBlur		= {handleBlur}
											value		= {values.capacidad}
											required
										/>
									</FloatingLabel>
									{errors.capacidad && (<Form.Label className	= 'Alert'>Ingrese el total de cupos</Form.Label>)}
								</Form.Group>

								<Form.Group as	= {Col} md	= "4" controlId	= "validationFormik01">
									<Form.Label><span>*</span>Piso</Form.Label>
									<FloatingLabel controlId	= "piso" label	= "Entrada">
										<Form.Select
											name		= "piso"
											onChange	= {handleChange}
											onBlur 		= {handleBlur}
											required
										>
											{floors.map((row, index) => (<Row_floors key	= {index} row	= {row} />))}
										</Form.Select>
									</FloatingLabel>
									{errors.piso && (<Form.Label className	= 'Alert'>Ingrese el total de cupos</Form.Label>)}
								</Form.Group>

								<Form.Group>
									<Button type="submit" disabled = {
																		isSubmitting ||
																		!!(errors.piso)}
									> CREAR </Button>
								</Form.Group>
							</Row>
						</Form>
					)
				}
			</Formik>
		</div>
	)
}





export function FormPuestosModify( props:{id:any}){
	console.log(props.id)
	const validate = Yup.object({
		capacidad: Yup.number().required('Required'),
	}).defined();

	const [create, setCreate] = useState([])

	return (
		<div>
			<Formik
					initialValues={{
						idEspacio: props.id,
						capacidad: undefined,
					}}

					validationSchema={validate}

					onSubmit={(values) => {
						console.log(JSON.stringify(values))
						fetch(
							process.env.REACT_APP_API_URL + "/update_floor_space/", {
																						mode: 'no-cors',
																						'method': 'POST',
																						headers: {
																							'Content-Type': 'applications/json',
																							'Accept': 'application/json',
																							'Access-Control-Allow-Origin': '*',
																							'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
																							'Access-Control-Request-Method': 'GET, POST, DELETE, PUT, OPTIONS',
																						},
																						body: JSON.stringify(values)
																					}
						)
						.then(  resp => resp.json())
						.then(	resp => {setCreate(resp)})
						.catch( error => console.log(error))
						.finally(refreshpage)
					}}
			>
				{
					({
						handleSubmit,
						handleChange,
						handleBlur,
						values,
						errors,
						isSubmitting
					})  => (
							<Form noValidate onSubmit={handleSubmit}>
								<Row>
									<Form.Group as	= {Col} md	= "6" controlId	= "validationFormik01">
										<Form.Label><span>*</span>Ingrese el total de cupos</Form.Label>
										<FloatingLabel controlId	= "capacidad" label	= "Ingrese la cantidad deseada">
											<Form.Control
												type		="number"
												name		= "capacidad"
												onChange	= {handleChange}
												onBlur 		= {handleBlur}									
												value		= {values.capacidad}
												required
											/>
										</FloatingLabel>

										{errors.capacidad && (<Form.Label className	= 'Alert'>Ingrese el total de cupos</Form.Label>)}
									</Form.Group>
									<Form.Group as	= {Col}>
										<Button type="submit" disabled = {
																			isSubmitting ||
																			!!(errors.capacidad)}
										> Modificar </Button>
									</Form.Group>
								</Row>
							</Form>
					)
				}
			</Formik>
		</div>
	)
}





function createDataFloors(
	piso: string,
	estado: string
) {
  return {
	piso: piso,
	estado:estado
	};
}

function Row_floors(props: { row: ReturnType<typeof createDataFloors> }) {
	const { row } = props;
	return (<option  value={row.piso} disabled={row.estado === 'False'}>{row.piso} - Piso {row.estado === 'False' ? "Desactivado": "Activado"}</option>);
}

const refreshpage = () => {window.location.reload()}