import { Formik }				from 'formik';
import *				as Yup	from "yup";

import Row						from 'react-bootstrap/Row';
import Col						from 'react-bootstrap/Col';
import Form						from 'react-bootstrap/Form';
import FloatingLabel			from 'react-bootstrap/FloatingLabel';
import Button					from 'react-bootstrap/Button';

import { useState, useEffect }	from 'react'





export function FormParqueaderoCreate(){
	const validate = Yup.object({
		nombre: Yup.string().required('Required'),
		capacidad: Yup.number().required('Required'),
	}).defined();

	const [create, setCreate] = useState([])
	const [vehicles,	setVehicles] 	= useState([])

	useEffect(() => {
		fetch(
			process.env.REACT_APP_API_URL + "/consult_parking_space/", {
																			'method': 'GET',
																			headers: {'Content-Type': 'applications/json'},
																		}
		)
		.then(  resp => resp.json())
		.then(  resp => {setVehicles(resp)})
		.catch( error => console.log(error))
	}, [])

	console.log(vehicles)

	return (
		<div>
			<h2>Creación de espacios en parqueadero</h2>
			<Formik
					initialValues={{
						idParqueadero: Math.floor(((new Date()).getTime() - (new Date(1970, 1, 1, 0, 0, 0, 0)).getTime()) / 1000),
						nombre: undefined,
						capacidad: undefined,
						creadoPor: localStorage.getItem('email')
					}}

					validationSchema={validate}

					onSubmit={(values) => {
						console.log(JSON.stringify(values))
						fetch(
							process.env.REACT_APP_API_URL + "/create_parking_space/", {
																						mode: 'no-cors',
																						'method': 'POST',
																						headers: {'Content-Type': 'applications/json'},
																						body: JSON.stringify(values)
																					}
						)
						.then(  resp => resp.json())
						.then(	resp => {setCreate(resp)})
						.catch( error => console.log(error))
						.finally(refreshpage)
					}}
			>
				{
					({
						handleSubmit,
						handleChange,
						handleBlur,
						values,
						errors,
						isSubmitting
					})  => (
							<Form noValidate onSubmit	= {handleSubmit}>
								<Row>
									<Form.Group as	= {Col} md =	"6" controlId	= "validationFormik01">
										<Form.Label><span>*</span>Ingrese el tipo de parqueadero</Form.Label>
										<FloatingLabel controlId	= "nombre" label	= "Ingrese el nombre">
											<Form.Control
												type		="text"
												name		= "nombre"
												onChange	= {handleChange}
												onBlur 		= {handleBlur}		
												value		= {values.nombre}
												required
											/>
										</FloatingLabel>
										{errors.nombre && (<Form.Label className	= 'Alert'>No se ha seleccionado nombre para el espacio</Form.Label>)}
										
									</Form.Group>
									<Form.Group as	= {Col} md =	"6" controlId	= "validationFormik01">
										<Form.Label><span>*</span>Ingrese el total de cupos</Form.Label>
										<FloatingLabel controlId	= "capacidad" label	= "Ingrese el cupo">
											<Form.Control
												type		="number"
												name		= "capacidad"
												onChange	= {handleChange}
												onBlur 		= {handleBlur}									
												value		= {values.capacidad}
												required
											/>
										</FloatingLabel>
										{errors.capacidad && (<Form.Label className	= 'Alert'>Ingrese el total de cupos</Form.Label>)}

									</Form.Group>
									<Form.Group>
										<Button type="submit" disabled = {
																			isSubmitting ||
																			!!(errors.capacidad) ||
																			!!(errors.nombre)
																		}
										> Crear </Button>
									</Form.Group>
								</Row>
							</Form>
					)
				}
			</Formik>
		</div>
	)
}





export function FormParqueaderoModify( props:{idParqueadero:any, capacidad: any}){
	const validate = Yup.object({
		capacidad: Yup.number().required('Required'),
	}).defined();

	const [create, setCreate] = useState([])

	return (
		<div>
			<Formik
					initialValues={{
						idParqueadero: props.idParqueadero,
						capacidad: undefined,
					}}

					validationSchema={validate}

					onSubmit={(values) => {
						console.log(JSON.stringify(values))
						fetch(
							process.env.REACT_APP_API_URL + "/update_parking_space/", {
																						mode: 'no-cors',
																						'method': 'POST',
																						headers: {'Content-Type': 'applications/json'},
																						body: JSON.stringify(values)
																					}
						)
						.then(  resp => resp.json())
						.then(	resp => {setCreate(resp)})
						.catch( error => console.log(error))
						.finally(refreshpage)
					}}
			>
				{
					({
						handleSubmit,
						handleChange,
						handleBlur,
						values,
						errors,
						isSubmitting
					})  => (
							<Form noValidate onSubmit={handleSubmit}>
								<Row>
									<Form.Group as	= {Col} md =	"6" controlId	= "validationFormik01">
										<Form.Label><span>*</span>Ingrese el total de cupos</Form.Label>
										<FloatingLabel controlId	= "capacidad" label	= "Ingrese la cantidad deseada">
											<Form.Control
												type		="number"
												name		= "capacidad"
												onChange	= {handleChange}
												onBlur 		= {handleBlur}									
												value		= {values.capacidad}
												required
											/>
										</FloatingLabel>

										{errors.capacidad && (<Form.Label className	= 'Alert'>Ingrese el total de cupos</Form.Label>)}
									</Form.Group>
									<Form.Group as	= {Col}>
										<Button type	= "submit" disabled = {
																				isSubmitting ||
																				!!(errors.capacidad)
																			}
										> Modificar </Button>
									</Form.Group>
								</Row>
							</Form>
					)
				}
			</Formik>
		</div>
	)
}

const refreshpage = () => {window.location.reload()}