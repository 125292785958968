import "./SideBarMenuItemView.scss"

import { SideBarMenuItem }	from "../../../types/types";
import { classNames }		from "../../../utils/classes";

import { styled } 			from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';



interface SideBarMenuItemViewProps {
	item: SideBarMenuItem;
	isOpen: boolean
}

export default function SideBarMenuItemView({
	item,
	isOpen
}:SideBarMenuItemViewProps){
	return (
			<div className	= "SideBarMenuItemView">
				<BootstrapTooltip title	= {item.tooltip} placement	= "right">
					<a href	= {item.url}>
						<div className = {classNames('ItemContent', isOpen? '': 'collapsed')}>
							<div className	= "icon"><item.icon/></div>
							<span className	= "label">{item.label}</span>
						</div>
					</a>
				</BootstrapTooltip>
			</div>
	)
}




const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]:		{ color: theme.palette.common.black, },
	[`& .${tooltipClasses.tooltip}`]:	{ backgroundColor: theme.palette.common.black, },
}));


