import { Formik }				from 'formik';
import *				as Yup	from "yup";

import Row						from 'react-bootstrap/Row';
import Col						from 'react-bootstrap/Col';
import Form						from 'react-bootstrap/Form';
import FloatingLabel			from 'react-bootstrap/FloatingLabel';
import Button					from 'react-bootstrap/Button';

import Skeleton					from '@mui/material/Skeleton';
import Stack					from '@mui/material/Stack';

import { useState, useEffect }	from 'react'

import { useNavigate } from 'react-router-dom';



export default function FormParqueaderoSolo(){
	const [days_reservation,	setDays]	= useState({'dias': [], 'parqueaderos':[]})
	const dias			= days_reservation["dias"] || []
	const parqueaderos	= days_reservation["parqueaderos"] || []
	
	const [create,		setCreate]		= useState([])

	const validate = Yup.object({
		idAgendamiento:	Yup.number().required('Required'),
		fecha:			Yup.string().required('Required'),
		horaInicio:		Yup.string().required('Required'),
		horaFin:		Yup.string().required('Required'),
		parqueadero:	Yup.string().required('Required'),
	}).defined();

	useEffect(() => {
		fetch(
			process.env.REACT_APP_API_URL + "/working_days/", {
																	'method': 'POST',
																	headers: {'Content-Type': 'applications/json'},
																	body: JSON.stringify({"email":localStorage.getItem('email')})
																}
		)
		.then(  resp => resp.json())
		.then(  resp => {setDays(resp)})
		.catch( error => console.log(error))
	}, [])

	if(dias.length === 0 && parqueaderos.length === 0){
		return  (
			<div>
				<Stack spacing	= {1}>
					<Row>
						<Form.Group	as	= {Col}	md	= "6"></Form.Group>
							<Skeleton variant	= "rounded"  height	= {40}	/>
							<Skeleton variant	= "rounded"  height	= {40}	/>
							<Row>
								<Form.Group	as	= {Col}	md	= "6"><Skeleton variant	= "rounded" height	= {40}/></Form.Group>
								<Form.Group	as	= {Col}	md	= "6"><Skeleton variant	= "rounded" height	= {40}/></Form.Group>
							</Row>
						<Form.Group	as	= {Col}	md	= "6">
							<Skeleton variant	= "rounded"  height	= {40}	/>
							<Skeleton variant	= "rounded"  height	= {40}	/>
							<Skeleton variant	= "rounded"  height	= {40}	/>
						</Form.Group>
						<Form.Group	as	= {Col}	md	= "12"><Skeleton variant	= "rounded"  height	= {40}/></Form.Group>
					</Row>
				</Stack>
				<p	className	= 'center_note'>No se ha obtenido una respuesta satisfactoria por el servidor, espere un momento por favor</p>
			</div>
		)
	}
	else if(dias.length === 0 ){	return (<h2>NO HAY DIAS DISPONIBLES PARA RESERVAR PARQUEADERO</h2>)}
	else if(parqueaderos.length === 0 ){	return (<h2>NO HAY ESPACIOS DISPONIBLES PARA RESERVAR PARQUEADERO</h2>)}
	else {
		console.log(dias)
		console.log(parqueaderos)

		return (
				<div>
					<Formik
						initialValues		= {{
													idAgendamiento:		Math.floor(((new Date()).getTime() - (new Date(1970, 1, 1, 0, 0, 0, 0)).getTime()) / 1000),
													nombreColaborador:	localStorage.getItem('displayName'),
													correo:				localStorage.getItem('email'),
													fecha:				undefined,
													horaInicio:			"00:01",
													horaFin:			"23:59",
													piso:				undefined,
													espacio:			undefined,
													parqueadero:		undefined,
						}}

						validationSchema	= {validate}

						onSubmit			= {(values) => {
													console.log(JSON.stringify(values))
													fetch(
														process.env.REACT_APP_API_URL + "/create_scheduling/", {
																													'method': 'POST',
																													headers: {'Content-Type': 'applications/json'},
																													body: JSON.stringify(values)
																												}
													)
														.then(resp => resp.json())
														.then(resp => {setCreate(resp)})
														.catch(error => console.error(error))
														.finally(refreshpage)
						}}
					>
						{
							({
								handleSubmit,
								handleChange,
								handleBlur,
								values,
								errors,
								isSubmitting
							})  => (

									<Form noValidate onSubmit	= {handleSubmit}>
										<Form.Label><span>NOTA:</span> Para realizar su agendamiento, debe de completar los campos obligatorios</Form.Label>
										<Row>
											<Form.Group	as	= {Col}	md	= "5">
												<Row>
													<Form.Group	as	= {Col}	md	= "12">
														<Form.Label><span>*</span>Fecha de la reserva</Form.Label>

														<FloatingLabel controlId	= "fecha" label	= "Fecha">
															<Form.Select
																name		= "fecha"
																onChange	= {handleChange}
																onBlur 		= {handleBlur}
																required
															>
																<option selected>Seleccione un fecha</option>
																{dias.map((row, index) => (<Row_days	key	= {index}	row	= {row}	/>))}
															</Form.Select>
														</FloatingLabel>
													</Form.Group>
												</Row>
											</Form.Group>

											<Form.Group	as	= {Col}	md	= "7">
												<Row>
													<Form.Group	as	= {Col}	md	= "12">
														<Form.Label>¿Que espacio de parqueadero desea reservar?</Form.Label>
														<FloatingLabel	controlId	= "parqueadero"	label	= "Vehiculo">
															<Form.Select
																name		= "parqueadero"
																onChange	= {handleChange}
																onBlur		= {handleBlur}
																disabled	= {values.fecha === undefined}
																required
															>
																<option selected>Seleccione un espacio</option>
																{parqueaderos.map((row, index) => (<Row_vehicle	key	= {index}	row	= {row}	fecha = {values.fecha}/>))}

															</Form.Select>
														</FloatingLabel>
													</Form.Group>
												</Row>
											</Form.Group>

											<Form.Group	as	= {Col}	md	= "12">
												<div	className	= "caja-alerta">													
													{errors.fecha		&&	(<Form.Label	className	= 'Alert'>FECHA DE AGENDAMIENTO</Form.Label>)}
													{errors.parqueadero	&&	(<Form.Label	className	= 'Alert'>PARQUEADERO PARA AGENDAR</Form.Label>)}

												</div>

												<Button	type	= "submit"	disabled	= {
														isSubmitting		||
														!!(errors.piso)		||	!!(errors.espacio)		||
														!!(errors.fecha)	||	!!(errors.horaInicio)	||
														!!(errors.horaFin)
													}
												> AGENDAR </Button>
											</Form.Group>

										</Row>
									</Form>
							)
						}
					</Formik>
				</div>
		)
	}
}





function createDataParking(
	dia: string,
	idParqueadero: number,
	nombre: string,
	capacidad: number,
	ocupacion: number
) {
  return {
		dia: dia,
		idParqueadero: idParqueadero,
		nombre: nombre,
		capacidad: capacidad,
		ocupacion: ocupacion

	};
}

function createDataDays(
	dia: string,
	nombre: string
) {
  return {
		dia: dia,
		nombre: nombre
	};
}


function Row_vehicle(props: { row: ReturnType<typeof createDataParking>, fecha:any}) {
	const { row, fecha }	= props;

	if(row.dia === fecha) {
		if((row.capacidad - row.ocupacion) > 0){
			return (<option value	= {row.idParqueadero}>{row.nombre}:	Disponibles({row.capacidad - row.ocupacion} de {row.capacidad})</option>);

		} else {	return (<option	value = "" disabled>{row.nombre}:  Sin espacio disponible</option>)}
	} else {	return (<option	className	= 'void' selected></option>)}	
}

function Row_days(props: { row: ReturnType<typeof createDataDays> }) {
	const { row }	= props;
	return (<option  value	= {row.dia}>{row.dia}	- {row.nombre}</option>);
}

const refreshpage = () => {window.location.replace("/MisReservas")}