import { useState }		from "react";
import { useMsal }		from "@azure/msal-react";
import { loginRequest }	from "../../authConfig";
import { Button }		from "@mui/material";





export const UnloggedPage = () => {
	const { instance } = useMsal();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleLogin = () => {
		setAnchorEl(null);
		instance.loginRedirect(loginRequest)
	}
	
	instance.loginRedirect(loginRequest)
	return (
		<>
			<h1>Aplicativo sin autenticación</h1>
			<p>Debes de estar logeado en el aplicativo para poder usarlo</p>
			
			<Button onClick	= {() => handleLogin()}>Iniciar Sesión</Button>
		</>
	)
};