import { useMsal }		from "@azure/msal-react";
import { MdLogout }		from "react-icons/md"





export default function Welcome(){
	const { instance } = useMsal();

	return (
		<>
			<div className	= 'caja_secundaria'>
				<>
					<h1>{localStorage.getItem('displayName')} <br></br><small>Bienvenido {localStorage.getItem('rol')}</small></h1>
					<li>Puedes agendar tus visitas a la fabrica de sueños Chevyplan</li>
					<li>Puedes consultar tus proximas visitas a la fabrica de sueños Chevyplan</li>
				</>

				{(() => {
					switch (localStorage.getItem('rol')) {
						case "Recepcionista":
							return (
								<>
									<li>Puedes agendar visitas extraordinarias a la fabrica de sueños Chevyplan</li>
								</>
							)

						case "Admin":
							return (
								<>
									<li>Puedes agendar visitas extraordinarias a la fabrica de sueños Chevyplan</li>
									<li>Puedes hacer cambios de Configuracion de los pisos, puestos de trabajo y parqueaderos</li>
								</>
							)

						case "SuperAdmin":
							return (
								<>
									<li>Puedes agendar visitas extraordinarias a la fabrica de sueños Chevyplan</li>
									<li>Puedes hacer cambios de Configuracion de los pisos, puestos de trabajo y parqueaderos</li>
									<li>Puedes hacer cambios de roles entre los usuarios ya registrados</li>
								</>
							)
					}
				})()}
			</div>
		</>
	);
}