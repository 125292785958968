import FormAgendamiento from '../complements/forms/forms_agendamiento'

import { Button }		from "@mui/material";
import { useState }		from "react";
import { useMsal }		from "@azure/msal-react";
import { loginRequest }	from "../../authConfig";





export default function Agendamiento(){
	const { instance } = useMsal();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleLogin = () => {
		setAnchorEl(null);
		instance.loginRedirect(loginRequest)
	}

	if(
		localStorage.getItem('rol') === "SuperAdmin" ||
		localStorage.getItem('rol') === "Admin" ||
		localStorage.getItem('rol') === "Recepcionista" ||
		localStorage.getItem('rol') === "Usuario"){
		return (
			<>
				<h1>AGENDAMIENTO DE ESPACIO DE TRABAJO</h1>
				<div className='caja_secundaria'>
					<FormAgendamiento />
				</div>
			</>
		);
	} else {
		return (
			<>
				<div className='caja_secundaria'>
					<h3>¡NO TIENE PERMISO DE INGRESO A ESTA OPCION! ={localStorage.getItem('rol')}</h3>
					<Button onClick	= {() => handleLogin()}>Iniciar Sesión</Button>

				</div>
			</>
		);
	}
}
