import *        as React 	from 'react';

import ReactDOM			from 'react-dom/client';
import App				from './App';

import './fonts/Louis/LouisBold.ttf'
import './fonts/Roboto/Roboto-Bold.ttf'
import './fonts/Roboto/Roboto-Light.ttf'
import './fonts/Roboto/Roboto-Regular.ttf'

// MSAL imports
import { PublicClientApplication, EventType, AuthenticationResult } from "@azure/msal-browser";


const pca =  new PublicClientApplication ({
	auth: {
		clientId: process.env.REACT_APP_ID+"",
		authority: process.env.REACT_APP_URL_AZURE+"",
	},
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    
});



pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        console.log(event)
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        pca.setActiveAccount(account);
    }
});



const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <App msalInstance  = {pca} />
);