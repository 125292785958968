import FormPisos			from '../../complements/forms/forms_pisos'
import CollapsibleTable		from '../../complements/tables/tables_pisos'





export default function Pisos(){
	return (
		<div className='caja_secundaria'>
			<CollapsibleTable/>
			<FormPisos />
		</div>
	);
}
