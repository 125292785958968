import Table 					from '@mui/material/Table';
import TableBody 				from '@mui/material/TableBody';
import TableCell 				from '@mui/material/TableCell';
import TableContainer 			from '@mui/material/TableContainer';
import TableHead				from '@mui/material/TableHead';
import TableRow 				from '@mui/material/TableRow';
import Paper 					from '@mui/material/Paper';
import Button					from '@mui/material/Button';
import Dialog					from '@mui/material/Dialog';
import DialogActions			from '@mui/material/DialogActions';
import DialogContent			from '@mui/material/DialogContent';
import Slide					from '@mui/material/Slide';
import { TransitionProps }		from '@mui/material/transitions';
import Skeleton				    from '@mui/material/Skeleton';
import Stack					from '@mui/material/Stack';
import BottomNavigationAction	from '@mui/material/BottomNavigationAction';

import * 			as React 	from 'react';
import { useState, useEffect }	from 'react'
import { TbEdit }	            from "react-icons/tb"





export default function TablesUsers() {
	const [data, setData] = useState<any[]>([]);
	const [dataAcceso, setDataAcceso] = useState([])

	useEffect(() => {
			fetch(
                process.env.REACT_APP_API_URL + "/consult_user/", {
                                                                        'method': 'GET',
                                                                        headers: {'Content-Type': 'applications/json'}
                                                                    }
			)
			.then( resp => resp.json())
			.then( resp => {setData(resp)})
			.catch(error => console.log(error))
	}, [])

    useEffect(() => {
        fetch(
            process.env.REACT_APP_API_URL + "/consult_access/", {
                                                                    'method': 'GET',
                                                                    headers: {'Content-Type': 'applications/json'}
                                                                }
        )
        .then( resp => resp.json())
        .then( resp => {setDataAcceso(resp)})
        .catch(error => console.log(error))
    }, [])

    console.log(data)
    console.log(dataAcceso)
    
    if(data.length > 0){
		return (
            <>
                <TableContainer component   = {Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align	= 'center' padding	= 'checkbox'>N°</TableCell>
                                <TableCell>Usuario</TableCell>
                                <TableCell>Correo</TableCell>
                                <TableCell align	= 'center' padding	= 'checkbox'>Archivo</TableCell>
                                <TableCell align	= 'center' padding	= 'checkbox'>Datacenter</TableCell>
                                <TableCell align	= 'center' padding	= 'checkbox'>Parqueadero</TableCell>
                                <TableCell align	= 'center' padding	= 'checkbox'>Piso 1</TableCell>
                                <TableCell align	= 'center' padding	= 'checkbox'>Editar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (<Row key	= {index} index	= {index} row	= {row} setData={setData}/>))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
		);
	} else {
		return  (
			<div>
				<Stack spacing={.5}>
					<Skeleton variant	= "rounded"  height	= {30} />
					<Skeleton variant	= "rounded"  height	= {40} />
				</Stack>
				<p className	= 'center_note'>No se ha obtenido una respuesta satisfactoria por el servidor, espere un momento por favor</p>
			</div>
		)
	}
}









function createData(email: string, nombre: string, rol: string, archivo: boolean, datacenter: boolean, parqueadero: boolean, pisoUno: boolean){
	return {
		email:email,
		nombre:nombre,
		rol:rol,
        archivo: archivo,
        datacenter: datacenter,
        parqueadero: parqueadero,
        pisoUno: pisoUno
    };
}

function Row(props: { index: any; row: ReturnType<typeof createData>; setData: React.Dispatch<React.SetStateAction<any[]>> }) {
    const { row, index, setData } = props;

	const [openModify, setOpenModify]	= React.useState(false);
	const handleClickOpenModify		= () => {setOpenModify(true);};
	const handleCloseModify			= () => {setOpenModify(false);};

    const handleCheckboxChange = (field: keyof typeof row) => {
        const newRow = { ...row, [field]: !row[field] };
        setData(prevData => {
            const newData = [...prevData];
            newData[index] = newRow;
            return newData;
        });
    };

	const handlesend	= async () => {
		fetch(
			process.env.REACT_APP_API_URL + "/update_user_device/", {
																	'method': 'POST',
																	headers: {'Content-Type': 'applications/json'},
																	body: JSON.stringify(row)
																}
		)
		.then( resp		=> resp.json())
		.then( resp		=> {setData(resp)})
		.catch(error	=> console.log(error))
		.finally(refreshpage)
	};

    return (
        <React.Fragment >
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align	= 'center' padding	='checkbox'>{index + 1}</TableCell>
                <TableCell>{row.nombre}</TableCell>
                <TableCell align	= 'center'>{row.email}</TableCell>
                <TableCell>
                    <input
                        type="checkbox"
                        checked={row.archivo}
                        onChange={() => handleCheckboxChange('archivo')}
                    />
                </TableCell>
                <TableCell>
                    <input
                        type="checkbox"
                        checked={row.datacenter}
                        onChange={() => handleCheckboxChange('datacenter')}
                    />
                </TableCell>
                <TableCell>
                    <input
                        type="checkbox"
                        checked={row.parqueadero}
                        onChange={() => handleCheckboxChange('parqueadero')}
                    />
                </TableCell>
                <TableCell>
                    <input
                        type="checkbox"
                        checked={row.pisoUno}
                        onChange={() => handleCheckboxChange('pisoUno')}
                    />
                </TableCell>
                <TableCell align	= 'center'>
                    {
                        <BottomNavigationAction icon = {<TbEdit />} onClick = {handleClickOpenModify}/>
                    }
                </TableCell>
            </TableRow>

            <Dialog
                open				= {openModify}
                TransitionComponent	= {Transition}
                keepMounted
                onClose			= {handleCloseModify}
                aria-describedby	= "alert-dialog-slide-description"
            >
                <div   className = 'SuperDialog'>
                    <h4>¿Desea guardar los cambios en <span>{row.email}</span>?</ h4>
                    <DialogContent>
                        <table>
                            <thead>
                                <tr>
                                    <th>Dispositivos</th>
                                    <th>Estado</th>
                                </tr>                                
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Archivo</td>
                                    <td>{String(row.archivo)}</td>
                                </tr>
                                <tr>
                                    <td>Datacenter</td>
                                    <td>{String(row.datacenter)}</td>
                                </tr>
                                <tr>
                                    <td>Parqueadero</td>
                                    <td>{String(row.parqueadero)}</td>
                                </tr>
                                <tr>
                                    <td>Piso 1</td>
                                    <td>{String(row.pisoUno)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </DialogContent>
                    <DialogActions>
                        <Button	onClick	= {handlesend}>Cambiar</Button>
                        <Button onClick	= {handleCloseModify} className	= "cancel">x</Button>
                    </DialogActions>
                </div>
            </Dialog>
        </React.Fragment>
    )
}

const Transition = React.forwardRef(function Transition(props: TransitionProps & {children: React.ReactElement<any, any>;}, ref: React.Ref<unknown>, ) {
	return <Slide direction="up" ref={ref} {...props} />;
});


const	refreshpage	= () => {window.location.reload()}