import { Formik }				from 'formik';
import *				as Yup	from "yup";

import Row						from 'react-bootstrap/Row';
import Col						from 'react-bootstrap/Col';
import Form						from 'react-bootstrap/Form';
import FloatingLabel			from 'react-bootstrap/FloatingLabel';
import Button					from 'react-bootstrap/Button';

import { useState }	from 'react'





export default function FormPisosCreate(){
	const validate = Yup.object({
		piso: Yup.number().required('Required')
	}).defined();

	const [create, setCreate] = useState([])

	return (
		<div>
			<h2>Crear piso</h2>
			<Formik
				initialValues	= {{
					piso: undefined,
					creadoPor: localStorage.getItem('email')
				}}

				validationSchema	= {validate}

				onSubmit={(values) => {
					console.log(JSON.stringify(values))
					fetch(
						process.env.REACT_APP_API_URL + "/create_floor/", {
																			'method': 'POST',
																			headers: {'Content-Type': 'applications/json'},
																			body: JSON.stringify(values)
																		}
					)
					.then(  resp => resp.json())
					.then(	resp => {setCreate(resp)})
					.catch( error => console.log(error))
					.finally(refreshpage)
				}}
			>
				{
					({
						handleSubmit,
						handleChange,
						handleBlur,
						values,
						errors,
						isSubmitting
					})  => (
							<Form noValidate onSubmit	= {handleSubmit}>
								<Row>
									<Form.Group as	= {Col} md	= "12" controlId	= "validationFormik01">
										<Form.Label><span>*</span>Ingrese el numero del piso</Form.Label>
										<FloatingLabel controlId	= "piso" label	= "Ingrese el número de piso">
											<Form.Control
												type		= "number"
												name		= "piso"
												onChange	= {handleChange}
												onBlur 		= {handleBlur}									
												value		= {values.piso}
												required
											/>
										</FloatingLabel>

										{errors.piso && (<Form.Label className	= 'Alert'>Ingrese el total de cupos</Form.Label>)}
									</Form.Group>

									<Form.Group>
										<Button type="submit" disabled = {
																			isSubmitting ||
																			!!(errors.piso)}
										> CREAR </Button>
									</Form.Group>
								</Row>
							</Form>
					)
				}
			</Formik>
		</div>
	)
}





export function FormPisosModify( props:{piso:number, estado:string}){
	let CambiarEstado = 0
	if(props.estado === "False"){CambiarEstado = 1 }
	
	const [create, setCreate] = useState([])

	return (
		<div>
			<Formik
					initialValues={{
						piso: props.piso,
						estado: CambiarEstado,
						creadoPor: localStorage.getItem('email')
					}}

					onSubmit={(values) => {
						console.log(JSON.stringify(values))
						fetch(
							process.env.REACT_APP_API_URL + "/update_floor/", {
																				mode: 'no-cors',
																				'method': 'POST',
																				headers: {'Content-Type': 'applications/json'},
																				body: JSON.stringify(values)
																			}
						)
						.then(  resp => resp.json())
						.then(	resp => {setCreate(resp)})
						.catch( error => console.log(error))
						.finally( refreshpage )
					}}
			>
				{
					({
						handleSubmit,
						isSubmitting
					})  => (
							<Form noValidate onSubmit={handleSubmit}>
								<Row>
									<Form.Group as	= {Col}>
										<Button type	= "submit" disabled	= {isSubmitting} >{CambiarEstado === 1 ? "ACTIVAR" : "DESACTIVAR"}</Button>
									</Form.Group>
								</Row>
							</Form>
					)
				}
			</Formik>
		</div>
	)
}

const refreshpage = () => {window.location.reload()}