import { FormAgendamientoModify }	from '../forms/forms_agendamiento';

import Collapse 					from '@mui/material/Collapse';
import IconButton 					from '@mui/material/IconButton';
import Table 						from '@mui/material/Table';
import TableBody 					from '@mui/material/TableBody';
import TableCell 					from '@mui/material/TableCell';
import TableContainer 				from '@mui/material/TableContainer';
import TableHead 					from '@mui/material/TableHead';
import TableRow 					from '@mui/material/TableRow';
import Typography 					from '@mui/material/Typography';
import Paper 						from '@mui/material/Paper';
import Grid 						from '@mui/material/Grid';
import BottomNavigationAction		from '@mui/material/BottomNavigationAction';
import Card 						from '@mui/material/Card';
import CardContent 					from '@mui/material/CardContent';
import Skeleton						from '@mui/material/Skeleton';
import Stack						from '@mui/material/Stack';
import Slide						from '@mui/material/Slide';
import { TransitionProps }			from '@mui/material/transitions';


import Button					from '@mui/material/Button';
import Dialog					from '@mui/material/Dialog';
import DialogActions			from '@mui/material/DialogActions';
import DialogContent			from '@mui/material/DialogContent';


import KeyboardArrowDownIcon 		from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon 			from '@mui/icons-material/KeyboardArrowUp';

import * 				as React 	from 'react';
import { useState, useEffect } 		from 'react'
import { FcApproval, FcCancel } 	from "react-icons/fc"
import { AiOutlineCar } 			from "react-icons/ai"
import { GiEntryDoor, GiExitDoor, GiDutchBike, GiDesk }	from "react-icons/gi"
import { BsFillPersonCheckFill, BsFillPersonXFill, BsBuilding }	from "react-icons/bs"
import { IoPersonOutline }			from "react-icons/io5"
import { RiEBikeLine }				from "react-icons/ri"
import { FaParking }				from "react-icons/fa"
import { TbSofa }					from "react-icons/tb"





export default function CollapsibleTable() {
	const [data, setData] = useState([])

	useEffect(() => {
			fetch(
				process.env.REACT_APP_API_URL + "/consult_scheduling/day", {
																				'method': 'GET',
																				headers: {'Content-Type': 'applications/json'}
																			}
			)
			.then( resp => resp.json())
			.then( resp => {setData(resp)})
			.catch(error => console.log(error))
	}, [])

	console.log(data)
	console.log(setData)
	if(data.length > 0){
		console.log(data)
		return (
				<>
					<h2>Reserva</h2>
					<TableContainer component	= {Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell align	= 'center' padding	= 'checkbox'>N°</TableCell>
									<TableCell>Colaborador</TableCell>
									<TableCell>Espacio</TableCell>
									<TableCell>Parqueadero</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data.map((row, index) => (<Row key	= {index} index	= {index} row	= {row} filter	= "1"/>))}
							</TableBody>
						</Table>
					</TableContainer>
				</>
		);
	} else {
		return  (
			<div>
				<Stack spacing={.5}>
					<Skeleton variant	= "rounded"  height	= {50} />
					<Skeleton variant	= "rounded"  height	= {50} />
					<Skeleton variant	= "rounded"  height	= {50} />
				</Stack>
				<p className	= 'center_note'>No se ha obtenido una respuesta de reservas del dia de hoy, vuelva a intentarlo</p>

			</div>
		)
	}
}





function createData(
	idAgendamiento:		string,
	nombreColaborador:	string,
	estado:				string,
	espacio:			string,
	fecha:				string,
	horaInicio:			string,
	horaFin:			string,
	parqueadero:		string,
){
	return {
		idAgendamiento:		idAgendamiento,
		nombreColaborador:	nombreColaborador,
		estado:				estado,
		espacio:			espacio,
		fecha:				fecha,
		horaInicio:			horaInicio,
		horaFin:			horaFin,
		parqueadero:		parqueadero
	}
}

function Row(props: { index:any, row: ReturnType<typeof createData>, filter:string }) {
	const {row, index, filter}	= props;

	const [openModify, setOpenModify]	= React.useState(false);

	if (row.estado === filter){
		return (
			<React.Fragment>
				<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
					<TableCell align	='center' padding	= 'checkbox'>{index + 1}</TableCell>
					<TableCell>{row.nombreColaborador}</TableCell>
					<TableCell>{row.espacio != undefined ? (
																			<h6>
																				{row.espacio === 'ESCRITORIO'  ? <GiDesk /> :
																					row.espacio === 'SOFA'  ? <TbSofa /> :
																						<BsBuilding />} <span>{row.espacio}</span></h6>
																		):(
																					<h6><FcCancel /> </h6>
												)}</TableCell>
					<TableCell>{row.parqueadero != undefined ? (
																			<h6>
																				{row.parqueadero === 'CARRO'  ? <AiOutlineCar /> :
																					row.parqueadero === 'MOTO'  ? <RiEBikeLine /> :
																						row.parqueadero === 'BICICLETA'  ? <GiDutchBike /> :
																						<FaParking />} <span>{row.parqueadero}</span></h6>
																		):(
																			<h6><FcCancel /> </h6>
												)}</TableCell>
				</TableRow>
			</React.Fragment>
		);
	} else {
        return (<></>)
    }
}

const Transition = React.forwardRef(function Transition(props: TransitionProps & {children: React.ReactElement<any, any>;}, ref: React.Ref<unknown>, ) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const refreshpage = () => {window.location.reload()}