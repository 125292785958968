import Collapse 					from '@mui/material/Collapse';
import IconButton 					from '@mui/material/IconButton';
import Table 						from '@mui/material/Table';
import TableBody 					from '@mui/material/TableBody';
import TableCell 					from '@mui/material/TableCell';
import TableContainer 				from '@mui/material/TableContainer';
import TableHead 					from '@mui/material/TableHead';
import TableRow 					from '@mui/material/TableRow';
import Typography 					from '@mui/material/Typography';
import Paper 						from '@mui/material/Paper';
import Grid 						from '@mui/material/Grid';
import BottomNavigationAction		from '@mui/material/BottomNavigationAction';
import { styled } 					from '@mui/material/styles';
import Card 						from '@mui/material/Card';
import CardContent 					from '@mui/material/CardContent';
import Skeleton						from '@mui/material/Skeleton';
import Stack						from '@mui/material/Stack';
import Slide						from '@mui/material/Slide';
import { TransitionProps }			from '@mui/material/transitions';
import Button						from '@mui/material/Button';
import Dialog						from '@mui/material/Dialog';
import DialogActions				from '@mui/material/DialogActions';

import KeyboardArrowDownIcon 		from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon 			from '@mui/icons-material/KeyboardArrowUp';


import * 				as React 	from 'react';
import { useState, useEffect } 		from 'react'
import { FcCancel} 					from "react-icons/fc"
import { RiDeleteBin5Line } 		from "react-icons/ri"
import { AiOutlineCar } 			from "react-icons/ai"
import { GiDutchBike, GiDesk }		from "react-icons/gi"
import { BsBuilding }				from "react-icons/bs"
import { RiEBikeLine }				from "react-icons/ri"
import { FaParking }				from "react-icons/fa"
import { TbSofa }					from "react-icons/tb"





export default function CollapsibleTable() {
	const [data, setData] = useState([])

	useEffect(() => {
			fetch(
				process.env.REACT_APP_API_URL + "/consult_scheduling/all/" + localStorage.getItem('email'), {
																													'method': 'GET',
																													headers: {'Content-Type': 'applications/json'}
																												}
			)
			.then(  resp => resp.json())
			.then(	resp => {setData(resp)})
			.catch(error => console.log(error))
	}, [])

	console.log(data)
	if(data.length > 0){
		return (
			<TableContainer component	= {Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell align	= 'center' padding	= 'checkbox'>N°</TableCell>
							<TableCell>Id Agendamiento</TableCell>
							<TableCell>Fecha</TableCell>
							<TableCell>Espacio</TableCell>
							<TableCell>Parqueadero</TableCell>
							<TableCell align	= 'center' padding	= 'checkbox'>Activación</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row, index) => (<Row key	= {index} index	= {index} row	= {row} />))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	} else {
		return  (
			<div>
				<Stack spacing	= {.5}>
					<Skeleton	variant	= "rounded"	height	= {40} />
					<Skeleton	variant	= "rounded"	height	= {50} />
				</Stack>
				<p className	= 'center_note'>No tienes agendamientos activos en tus reservas</p>

			</div>
		)
	}
}





function createData(
	idAgendamiento: string,
	nombreColaborador: string,
	estado: string,
	espacio: string,
	fecha: string,
	horaInicio: string,
	horaFin: string,
	parqueadero: string,
){
	return {
		idAgendamiento: idAgendamiento,
		nombreColaborador: nombreColaborador,
		estado: estado,
		espacio: espacio,
		fecha: fecha,
		horaInicio: horaInicio,
		horaFin: horaFin,
		parqueadero: parqueadero
	}
}

function Row(props: { index:any, row: ReturnType<typeof createData> }) {
	const { row, index }	= props;
	const [data, setData]	= useState([])

	const [open, setOpen] = React.useState(false);

	const [openDelete, setOpenDelete]	= useState(false);
	const handleClickOpenDelete			= () => {setOpenDelete(true);};
	const handleCloseDelete				= () => {setOpenDelete(false);};

	const handlesend				= async () => {
		fetch(
			process.env.REACT_APP_API_URL + "/delete_scheduling/", {
																		'method': 'DELETE',
																		headers: {'Content-Type': 'applications/json'},
																		body: JSON.stringify(row)
																	}
		)
		.then( resp		=> resp.json())
		.then( resp		=> {setData(resp)})
		.catch(error	=> console.log(error))
		.finally(refreshpage)
	};

	console.log(new Date().toISOString().split('T')[0])
	return (
		<React.Fragment>
			<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell align	='center' padding	='checkbox'>{index + 1}</TableCell>
				<TableCell>{row.idAgendamiento}</TableCell>
				<TableCell>{row.fecha}</TableCell>
				<TableCell>{row.espacio != undefined ? (
																			<h6>
																				{row.espacio === 'ESCRITORIO'  ? <GiDesk /> :
																					row.espacio === 'SOFA'  ? <TbSofa /> :
																						<BsBuilding />} <span>{row.espacio}</span></h6>
																		):(
																					<h6><FcCancel /> </h6>
												)}</TableCell>
				<TableCell>{row.parqueadero != undefined ? (
																			<h6>
																				{row.parqueadero === 'CARRO'  ? <AiOutlineCar /> :
																					row.parqueadero === 'MOTO'  ? <RiEBikeLine /> :
																						row.parqueadero === 'BICICLETA'  ? <GiDutchBike /> :
																						<FaParking />} <span>{row.parqueadero}</span></h6>
																		):(
																			<h6><FcCancel /> </h6>
												)}</TableCell>
				<TableCell align	= 'center' padding	= 'checkbox'>
					{row.fecha >= new Date().toISOString().split('T')[0] ? (
						<BottomNavigationAction icon = {<RiDeleteBin5Line />}  onClick	= {handleClickOpenDelete}/>
					):(
						<p></p>
					)}
				</TableCell>
			</TableRow>

			<Dialog
				open				= {openDelete}
				TransitionComponent	= {Transition}
				keepMounted
				onClose				= {handleCloseDelete}
				aria-describedby	= "alert-dialog-slide-description"
			>
				<div	className	= 'SuperDialog'>
					<h4>¿Desea eliminar el agendamiento: <span>{row.idAgendamiento}</span> de tus reservas?</ h4>

					<DialogActions>
						<Button	onClick	= {handlesend}>Eliminar</Button>
						<Button	onClick	= {handleCloseDelete}	className	= "cancel">x</Button>
					</DialogActions>
				</div>
			</Dialog>
			
		</React.Fragment>
	);
}

const Transition = React.forwardRef(function Transition(props: TransitionProps & {children: React.ReactElement<any, any>;}, ref: React.Ref<unknown>, ) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const refreshpage = () => {window.location.reload()}