import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
	auth: {
		clientId: "f0847123-09bb-4077-9865-07778052c6a8",
		authority: "https://login.microsoftonline.com/64bafaa9-388a-414b-8df5-374840918571",
		redirectUri: "/",
		postLogoutRedirectUri: "/"
	}
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
	scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
	graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};
