import Table 						from '@mui/material/Table';
import TableBody 					from '@mui/material/TableBody';
import TableCell 					from '@mui/material/TableCell';
import TableContainer 				from '@mui/material/TableContainer';
import TableHead 					from '@mui/material/TableHead';
import TableRow 					from '@mui/material/TableRow';
import Paper 						from '@mui/material/Paper';
import Skeleton						from '@mui/material/Skeleton';
import Stack						from '@mui/material/Stack';

import * 				as React 	from 'react';
import { useState, useEffect } 		from 'react'

import * as FileSaver				from 'file-saver';
import * as XLSX					from 'xlsx';





export default function CollapsibleTable() {
	const fileType		= 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension	= '.xlsx';
	const fileName		= 'Agendamiento: ' + Date().toLocaleString();

	const [dataReport, setData] = useState([])
	const reportData:any = dataReport

    const exportToCSV = () => {
		for (let index = 0; index < reportData.length; index++) {
			if (reportData[index]['estado'] === "1"){reportData[index]['estado']	= "Reservado" }
			if (reportData[index]['estado'] === "2"){reportData[index]['estado']	= "Usado" }
			if (reportData[index]['estado'] === "3"){reportData[index]['estado']	= "Cancelado" }

		}
        const ws			= XLSX.utils.json_to_sheet(reportData);
        const wb			= { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer	= XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data			= new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

	useEffect(() => {
			fetch(
				process.env.REACT_APP_API_URL + "/consult_scheduling/all", {
																				'method': 'GET',
																				headers: {'Content-Type': 'applications/json'}
																			}
			)
			.then( resp => resp.json())
			.then( resp => {setData(resp)})
			.catch(error => console.log(error))
	}, [])

	console.log(dataReport)
	if(dataReport.length > 0){
		for(let element of dataReport){
			console.log(element)
		}
		return (
			<>
				<button onClick	= {(e) => exportToCSV()}>Exportar CSV</button>
			</>
		);
	} else {
		return  (
			<div>
				<Stack spacing	= {.5}>
					<Skeleton variant	= "rounded"  height	= {30} />
					<Skeleton variant	= "rounded"  height	= {40} />
				</Stack>
				<p className	= 'center_note'>No se ha obtenido una respuesta satisfactoria por el servidor, Intentelo de nuevo</p>

			</div>
		)
	}
}




function createData(
	idAgendamiento:		string,
	nombreColaborador:	string,
	estado:				string,
	fecha:				string,
	horaInicio:			string,
	horaFin:			string,
	espacio:			string,
	piso:				string,
	parqueadero:		string,
	fechaCreacion:		string,
){
	return {
		idAgendamiento:		idAgendamiento,
		nombreColaborador:	nombreColaborador,
		estado:				estado,
		fecha:				fecha,
		horaInicio:			horaInicio,
		horaFin:			horaFin,
        piso:				piso,
		espacio:			espacio,
		parqueadero:		parqueadero,
        fechaCreacion:		fechaCreacion
	}
}

function Row(props: { index:any, row: ReturnType<typeof createData> }) {
	const { row, index }	= props;

	var estado = "Reservado"
	if (row.estado === "2"){estado	= "Usado" }
	if (row.estado === "3"){estado	= "Cancelado" }

	return (
		<React.Fragment>
			<TableRow sx	= {{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell align	= 'center' padding	= 'checkbox'>{row.nombreColaborador}</TableCell>
				<TableCell align	= 'center' padding	= 'checkbox'>{estado}</TableCell>
				<TableCell align	= 'center' padding	= 'checkbox'>{row.fecha}</TableCell>
				<TableCell align	= 'center' padding	= 'checkbox'>{row.horaInicio} - {row.horaFin}</TableCell>
				<TableCell align	= 'center' padding	= 'checkbox'>{row.piso}</TableCell>
				<TableCell align	= 'center' padding	= 'checkbox'>{row.espacio}</TableCell>
				<TableCell align	= 'center' padding	= 'checkbox'>{row.parqueadero}</TableCell>
			</TableRow>
		</React.Fragment>
	);
}