import {FormParqueaderoCreate }		from '../../complements/forms/forms_parqueadero';
import CollapsibleTable 			from '../../complements/tables/tables_parqueadero'





export default function Parqueadero(){
	return (
		<div className='caja_secundaria'>
			<CollapsibleTable/>
			<FormParqueaderoCreate />
		</div>
	);
}
