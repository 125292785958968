import "./App.scss"
import "./scss/cards.scss"
import "./scss/carnet.scss"
import "./scss/corporativo.scss"
import "./scss/dashboard.scss"
import "./scss/elementos.scss"
import "./scss/formularios.scss"
import "./scss/modals.scss"
import "./scss/reset.scss"
import "./scss/table.scss"
import "./scss/variables.scss"
import "./scss/widget-config.scss"

import 'bootstrap/dist/css/bootstrap.min.css';

import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider}	from "@azure/msal-react";
import { IPublicClientApplication }										from "@azure/msal-browser";

import { Routes, Route, BrowserRouter}			from 'react-router-dom'

import { SideBarMenu }							from "./components/menu/sidebars/SideBarMenu"

import slider									from './imgs/logos/ChevyPlan-Gris-corbatin-Textura.svg'

import { UnloggedPage }							from "./components/routes/routes_login";
import Agendamiento								from "./components/routes/routes_agendamiento";
import Parqueadero								from "./components/routes/routes_parqueadero";
import { MisReservas }							from "./components/routes/routes_mis_reservas";
import ReservasDelDia							from "./components/routes/routes_reservas_del_dia";
import Configuracion							from "./components/routes/routes_config";
import Reportes									from "./components/routes/routes_reportes";
import Administracion							from "./components/routes/routes_admin";
import Welcome									from "./components/routes/routes_welcome";








export type AppProps = { msalInstance: IPublicClientApplication;};

function App({ msalInstance }:AppProps) {

	return (
		<MsalProvider instance	= {msalInstance}>
			<div className="body">
					<AuthenticatedTemplate>
						<SideBarMenu/>
						<div className = "content">
							<div className = "slider"><img src = {slider}/></div>
							<div className='caja_principal'>
								<BrowserRouter>
									<Routes>
										<Route path	= '/Administracion'				element	= {<Administracion />}/>

										<Route path	= '/Configuracion'				element	= {<Configuracion />}/>
										<Route path	= '/Reportes'					element	= {<Reportes />}/>
	
										<Route path	= '/ReservasDelDia'				element	= {<ReservasDelDia />}/>

										<Route path	= '/Agendamiento'				element	= {<Agendamiento />}/>
										<Route path	= '/Parqueadero'				element	= {<Parqueadero />}/>
										<Route path	= '/MisReservas'				element	= {<MisReservas />}/>
										<Route path	= '/'							element	= {<Welcome />}/>
										<Route path	= '*'							element	= {<main><p>Direccion no encontrada o no posee permisos para ingresar a esta opción, por favor contactese con el administrador</p></main>}/>
									</Routes>
								</BrowserRouter>
							</div>
						</div>
					</AuthenticatedTemplate>

					<UnauthenticatedTemplate>
						<div className	= "content">
							<div className	= "slider"><img src	= {slider}/></div>
							<div className	= 'caja_principal'>{<UnloggedPage />}</div>
						</div>
					</UnauthenticatedTemplate>
			</div>
		</MsalProvider>
	)		
}

export default App;