import TablesUsers	from '../../complements/tables/tables_accesos_usuarios'




export default function PermisosUsuarios(){
	return (
		<div className='caja_secundaria'>
			<TablesUsers/>
		</div>
	);
}
