import { FormParqueaderoModify } from '../forms/forms_parqueadero';

import Table					from '@mui/material/Table';
import TableBody				from '@mui/material/TableBody';
import TableCell				from '@mui/material/TableCell';
import TableContainer			from '@mui/material/TableContainer';
import TableHead				from '@mui/material/TableHead';
import TableRow					from '@mui/material/TableRow';
import Paper					from '@mui/material/Paper';
import BottomNavigationAction	from '@mui/material/BottomNavigationAction';
import Button					from '@mui/material/Button';
import Dialog					from '@mui/material/Dialog';
import DialogActions			from '@mui/material/DialogActions';
import DialogContent			from '@mui/material/DialogContent';
import Slide					from '@mui/material/Slide';
import { TransitionProps }		from '@mui/material/transitions';
import Skeleton					from '@mui/material/Skeleton';
import Stack					from '@mui/material/Stack';

import * 			as React 	from 'react';
import { useState, useEffect }	from 'react'
import { TbEdit }				from "react-icons/tb"
import { RiDeleteBin5Line }		from "react-icons/ri"





export default function CollapsibleTable() {
	const [data, setData] = useState([])

	useEffect(() => {
					fetch(
						process.env.REACT_APP_API_URL + "/consult_parking_space/", {
																						'method': 'GET',
																						headers: {'Content-Type': 'applications/json'}
																					}
					)
					.then( resp => resp.json())
					.then( resp => {setData(resp)})
					.catch(error => console.log(error))
					
				}, [])

	console.log(data)
	if(data.length > 0){
		return (
			<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell align	='center' padding	='checkbox'>N°</TableCell>
						<TableCell>Tipo parqueadero</TableCell>
						<TableCell align='center'>Capacidad</TableCell>
						<TableCell align='center'>Editar</TableCell>
						<TableCell align='center'>Eliminar</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((row, index) => (<Row key={index} index={index} row={row} />))}
				</TableBody>
			</Table>
			</TableContainer>
		);
	} else {
		return  (
			<div>
				<Stack spacing={.5}>
					<Skeleton variant="rounded"  height={30} />
					<Skeleton variant="rounded"  height={40} />
				</Stack>
				<p className='center_note'>No se ha obtenido una respuesta satisfactoria por el servidor, espere un momento por favor</p>
			</div>
		)
	}
}





function createData(
	idParqueadero: number,
	nombre: string,
	capacidad: string
) {
	return {
		nombre: nombre,
		capacidad: capacidad,
		idParqueadero: idParqueadero
	};
}

function Row(props: { index:any, row: ReturnType<typeof createData> }) {
	const { row, index }	= props;
	const [data, setData]	= useState([])

	const [openModify, setOpenModify]	= useState(false);
	const handleClickOpenModify			= () => {setOpenModify(true);};
	const handleCloseModify				= () => {setOpenModify(false);};

	const [openDelete, setOpenDelete]	= useState(false);
	const handleClickOpenDelete			= () => {setOpenDelete(true);};
	const handleCloseDelete				= () => {setOpenDelete(false);};

	const handlesend				= async () => {
		fetch(
			process.env.REACT_APP_API_URL + "/delete_parking_space/", {
																		'method': 'DELETE',
																		headers: {'Content-Type': 'applications/json'},
																		body: JSON.stringify(row)
																	}
		)
		.then( resp		=> resp.json())
		.then( resp		=> {setData(resp)})
		.catch(error	=> console.log(error))
		.finally(refreshpage)
	};

	return (
		<React.Fragment>
			<TableRow sx	= {{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell align	='center' padding	='checkbox'>{index + 1}</TableCell>
				<TableCell align	= 'justify'>{row.nombre}</TableCell>
				<TableCell align	= 'center' padding	= 'checkbox'>{row.capacidad}</TableCell>
				<TableCell align	= 'center' padding	= 'checkbox'><BottomNavigationAction icon	= {<TbEdit />} onClick	= {handleClickOpenModify}/></TableCell>
				<TableCell align	= 'center' padding	= 'checkbox'><BottomNavigationAction icon	= {<RiDeleteBin5Line />} onClick	= {handleClickOpenDelete}/> </TableCell>
			</TableRow>


			<Dialog
				open				= {openDelete}
				TransitionComponent	= {Transition}
				keepMounted
				onClose				= {handleCloseDelete}
				aria-describedby	= "alert-dialog-slide-description"
			>
				<div	className	= 'SuperDialog'>
					<h4>¿Desea eliminar <span>{row.nombre}</span> como opción?</ h4>

					<DialogActions>
						<Button	onClick	= {handlesend}>Eliminar</Button>
						<Button	onClick	= {handleCloseDelete}	className	= "cancel">x</Button>
					</DialogActions>
				</div>
			</Dialog>

			<Dialog
				open				= {openModify}
				TransitionComponent	= {Transition}
				keepMounted
				onClose				= {handleCloseModify}
				aria-describedby	= "alert-dialog-slide-description"
			>
				<div	className	= 'SuperDialog'>
					<h4>¿Desea modificar los espacios disponibles para la sección <span>{row.nombre}</span> ?</ h4>
					<DialogContent><FormParqueaderoModify idParqueadero	= {row.idParqueadero} capacidad ={row.capacidad}/></DialogContent>
					<DialogActions><Button onClick	= {handleCloseModify} className	= "cancel">x</Button></DialogActions>
				</div>
			</Dialog>
		</React.Fragment>
	);
}

const Transition = React.forwardRef(function Transition(props: TransitionProps & {children: React.ReactElement<any, any>;}, ref: React.Ref<unknown>, ) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const refreshpage = () => {window.location.reload()}