import FormPuestos 		from '../../complements/forms/forms_puestos'
import CollapsibleTable 	from '../../complements/tables/tables_puestos'





export default function Puestos(){
	return (
		<div className='caja_secundaria'>
			<CollapsibleTable/>
			<FormPuestos />
		</div>
	);
}
